.summary{
    display: flex;
    justify-content: space-between;
  
    .orders{
        min-height: 20vh;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        background-color: #9370DB;
        // justify-content: space-between;
        .upper{
            display: flex;
            justify-content: space-between;
            gap: 25px;

            .left{
                display: flex;
                flex-direction: column;
                flex: 1;
                .title{
                    font-size: 18px;
                    font-weight: 500;
                }
                .figure{
                    color: white;
                    // font-size: 20px;
                    // font-weight: 500;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;
                    
                    .rep{
                        color: white;
                        font-size: 12px;
                        &:hover{
                            color: rgb(54, 33, 33);
                        }
                    }
                }
                .percentage{
                    font-size: 14px;
                    color: white;
                    font-weight: 300;
                }
            }
            .right{
                span{
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-top: 10px;
                    color: #9370DB;
                    font-weight: 500;
                }
            }
        }
        .lower{
            color: #a481e4;
            font-size: 78px;
        }
    }
    .payments{
        min-height: 20vh;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        background-color:  #29AB87;
        .upper{
            display: flex;
            justify-content: space-between;
            gap: 25px;

            .left{
                display: flex;
                flex-direction: column;
                flex: 1;
                .title{
                    font-size: 18px;
                    font-weight: 500;
                }
                .figure{
                    color: white;
                    // font-size: 20px;
                    // font-weight: 500;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;
                    
                    .rep{
                        color: white;
                        font-size: 12px;
                        &:hover{
                            color: rgb(54, 33, 33);
                        }
                    }
                }
                .percentage{
                    font-size: 14px;
                    color: white;
                    font-weight: 300;
                }
            }
            .right{
                span{
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-top: 10px;
                    color: #9370DB;
                    font-weight: 500;
                }
            }
        }
    }
    .deductions{
        min-height: 20vh;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        background-color: #6495ED;
        .upper{
            display: flex;
            justify-content: space-between;
            gap: 25px;

            .left{
                display: flex;
                flex-direction: column;
                flex: 1;
                .title{
                    font-size: 18px;
                    font-weight: 500;
                }
                .figure{
                    color: white;
                    // font-size: 20px;
                    // font-weight: 500;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;
                    
                    .rep{
                        color: white;
                        font-size: 12px;
                        &:hover{
                            color: rgb(54, 33, 33);
                        }
                    }
                }
                .percentage{
                    font-size: 14px;
                    color: white;
                    font-weight: 300;
                }
            }
            .right{
                span{
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-top: 10px;
                    color: #9370DB;
                    font-weight: 500;
                }
            }
        }
    }
    .newusers{
        min-height: 20vh;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        background-color: #FCAE1E;
        .upper{
            display: flex;
            justify-content: space-between;
            .left{
                display: flex;
                flex-direction: column;
                .title{
                    font-size: 13px;
                    font-weight: 500;
                }
                .figure{
                    color: white;
                    font-size: 20px;
                    font-weight: 500;
                }
                .percentage{
                    font-size: 12px;
                    color: white;
                    font-weight: 300;
                }
            }
            .right{
                span{
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-top: 10px;
                    color: #FCAE1E;
                    font-weight: 500;
                }
            }
        }
    }
}