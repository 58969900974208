.separateMain{
    display: flex;
    flex-direction: column;
    display: flex;
    gap: 10px;
    .datearea{
        z-index: 999;
        width: 12vw;
    }


    .rangeHome{
        display: flex;
        margin-top: 20px;
        background-color: white;
        padding: 5px;
        gap: 10px;
        .left{
            display: flex;
            flex-direction: column;
            .title{
                font-weight: 500;
            }
            .heading{
                font-size: 12px;
            }
        }
        .right{
            display: flex;
            gap: 20px;
            align-items: center;
            .leftSide{
                background-color: white;
                padding: 5px;
                display: flex;
                gap: 5px;
                .day, .week, .month{
                    font-size: 12px;
                    border-right: 1px solid lightgray;
                    padding: 0px 5px;
                }
                .year{
                    padding: 0px 5px;
                    font-size: 12px;
                }
                
            }
            .rightSide{
                display: flex;
                gap: 8px;
                background-color: white;
                padding: 5px;
                align-items: center;
                button{
                    background-color: rgb(20, 143, 238);
                    // background:  rgb(15, 124, 207);
                    color: white;
                    padding: 5px 10px;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    border-radius: 2px;
                }
                .visible{
                    font-size: 12px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    border-left: 1px solid lightgray;
                    border-right: 1px solid lightgray;
                    padding: 0px 10px;
                }
                .back{
                    font-size: 8px;
                    color: gray;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .dataArea{
        display: flex;
        gap: 10px;
        .right{
            flex: 1;
            background-color:white;
            padding: 10px;
            .leftt{
                img{
                    height: 30px;
                }
            }
            .heading{
                font-size: 13px;
                font-weight: 500;
            }
            .totalammounts{
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .amtHeader{
                    font-size: 13px;
                }
                .amtamounT{
                    font-size: 18px;
                    font-weight: 500;
                    span{
                        font-size: 12px;
                        font-weight: 300;
                    }
                }

            }

        }
        .left{
            flex: 1;
            background-color: white;
            padding: 10px;
            .leftt{
                img{
                    height: 30px;
                }
            }
            .heading{
                font-size: 13px;
                font-weight: 500;
            }
            .totalammounts{
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .amtHeader{
                    font-size: 13px;
                }
                .amtamounT{
                    font-size: 18px;
                    font-weight: 500;
                    span{
                        font-size: 12px;
                        font-weight: 300;
                    }
                }

            }
        }

    }
}
