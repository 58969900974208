$mainColor: #28231D;
$onHover: #E3242B;
$amazongreen: #007185;
$amazon: #f3d078;
$amazonorange: #FF8F00;
.orderbody{
    // min-height: 100vh;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    background-color: red;
    .container{
        // width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        .top{
            background-color: rgb(235, 232, 232);
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .orderstatuses{
                font-size: 18px;
                font-weight: 500;
            }
            .items{
                display: flex;
                gap: 10px;
                span{
                    padding: 2px 2px;
                    font-size: 13px;
                    cursor: pointer;
                }
                .active{
                    border-top: 1px solid red;
                    color: black;
                    background-color: rgb(221, 219, 219);
                }
            }
        }
        .down{
            .dTop{
                background-color: rgb(247, 247, 247);
                padding: 16px;
                color: $onHover;
            }
            .dDown{
                background-color: rgb(255, 255, 255);
                min-height: 100vh;
            }

        }

    }
}