.selectedmessage{
    background-color:rgb(248, 224, 193);
}
.singlemessage{
    display: flex;
    gap: 5px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom: 7px;
    padding-top: 7px;

    border-bottom: 1px solid rgb(235, 232, 232);
    align-items: center;
    cursor: pointer;
    &:hover{
        background-color: rgb(248, 224, 193);
    }
    color: gray;
    .profilePhoto{
        height: 50px;
        width: 50px;
        .profilephotoimg{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            background-color: rgb(197, 196, 196);
        }
    }
    .nameMessage{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .nametime{
            display: flex;
            justify-content: space-between;
            .name{
                font-size: 14px;
                font-weight: 500;
            }
            .time{
                font-size: 10px;
            }
            
        }
        .message{
            font-size: 12px;
        }
    }
}