.locationsLocation{
    position: relative;
}
.modaal{
    position: absolute;
    height: 100vh;
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .child{
        width: 30vw;
        height: 20vh;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        justify-content: center;
        align-items: center;
        // font-weight: 500;
        font-size: 24px;
        display: flex;
        color: rgb(49, 209, 209);
    }
}