.range{
    display: flex;
    justify-content: space-between;
    .left{
        display: flex;
        flex-direction: column;
        .title{
            font-weight: 500;
        }
        .heading{
            font-size: 12px;
        }
    }
    .right{
        display: flex;
        gap: 20px;
        align-items: center;
        .leftSide{
            background-color: white;
            padding: 5px;
            display: flex;
            gap: 5px;
            .day, .week, .month{
                font-size: 12px;
                border-right: 1px solid lightgray;
                padding: 0px 5px;
            }
            .year{
                padding: 0px 5px;
                font-size: 12px;
            }
            
        }
        .rightSide{
            display: flex;
            gap: 8px;
            background-color: white;
            padding: 5px;
            align-items: center;
            .visible{
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                border-left: 1px solid lightgray;
                border-right: 1px solid lightgray;
                padding: 0px 10px;
            }
            .back{
                font-size: 8px;
                color: gray;
                display: flex;
                align-items: center;
            }
        }
    }
}