.layout{
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(243, 242, 242);
    .navbar{
        background-color: black;
    }
    .mainarea{
        display: flex;
        flex: 1;
        color: black;
        .sidebar{
            min-width: 12%;
            background-color: black;
            padding: 10px 16px;
        }
        .wholebar{
            flex: 1;
            padding: 0px 16px;
            overflow-y: scroll;
            height: 100vh;
        }
    }
}