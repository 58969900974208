.trending{
    display: flex;
    justify-content: space-between;
    height: 10vh;
    padding: 5px 15px;
    gap: 10px;
    border-bottom: 1px solid rgb(224, 223, 223);
    .middle{
        width: 75%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgb(231, 231, 231);
        justify-content: center;
        .title{
            font-size: 14px;
            font-weight: 500;
        }
        .category{
            font-size: 12px;
        }
        .route{
            font-size: 12px;
            width: 100%;
        }
    }
    .left{
        background-color: gray;
        width: 15%;
        .imgdiv{
            height: 10vh;
            img{
                height: 10vh;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .total{
            font-size: 18px;
            span{
                font-size: 12px;
            }
        }
        span{
            font-size: 12px;
        }
    }
}