$mainColor: #28231D;
$onHover: #E3242B;
$amazongreen: #007185;
$amazon: #f3d078;
$amazonorange: #FF8F00;
.categoriesHome{
  position: relative;
}
.editt{
  position: absolute;
  left:0;
  top:0;
  height: 100%;
  width: 100%;
  // background-color: red;
  display: flex;
  // align-items: center;
  justify-content: center;
  z-index: 999;
  .insideedit{
    width: 25%;
    min-height: 50%;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    .insideheading{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon{
        cursor: pointer;
        font-size: 18px;
      }
      .insideword{
        font-size: 18px;
        font-weight: 500;
      }
    }
    .inputinside{
      padding: 5px 10px;
      outline: none;
    }
    button{
      padding: 5px;
      cursor: pointer;
      background-color: $amazongreen;
      color: white;
    }
    .button{
      padding: 5px;
      cursor: pointer;
      background-color: $onHover;
      color: white;
      border: none;
    }
    .loaderbar{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      flex-direction: column;
    }
    .error{
      color: $onHover;
      font-size: 13px;
    }
  }
}
.bold-header {
    font-weight: bold;
  }
.select3edtitle{
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}
.view{
  font-size: 15px;
  color: gray;
  cursor: pointer;
  &:hover{
    color: $amazongreen;
  }
}
.delete{
  font-size: 15px;
  color: gray;
  cursor: pointer;
  &:hover{
    color: $onHover;
  }
} 
.edit{
  font-size: 15px;
  color: gray;
  cursor: pointer;
  &:hover{
    color: $amazon;
  }
}

.selectionList{
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  position: absolute;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: white;
  width: 200px;
  top: 30px;
  max-height: 70vh;
  overflow: hidden;
  .headingList{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon{
          font-size: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
      }
      .title{
          display: flex;
          align-items: center;
      }
  }
  .containerList{
      font-size: 13px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;
      span{
          cursor: pointer;
          padding-bottom: 5px;
          border-bottom:  solid 1px rgb(228, 224, 224);
          font-size: 13px;
          transition: transform 0.3s, color 0.3s;
          &:hover{
              transform: scale(1, 0.9); /* Zoom out effect */
              color: $amazongreen; /* Change the text color to black on hover */
          }
      }
  }
}

.availablelists{
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 13px;
}