$mainColor: #28231D;
$onHover: #E3242B;
$amazongreen: #007185;
$amazon: #f3d078;
$amazonorange: #FF8F00;
.addproductsStaff{
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    position: relative;
    .upper{
        background-color: white;
        padding: 16px;
        .title{
            font-size: 24px;
            font-weight: 500;
        }
        .subtitle{
            font-size: 12px;
        }
        .buttons{
            display: flex;
            gap: 20px;
            margin-top: 20px;
            .leftbutton{
                background-color: rgb(32, 31, 31);
                color: white;
                padding: 2px 10px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .rightbutton{
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                border: 1px solid lightgray;
                padding: 2px 10px;
                border-radius: 5px ;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .lower{
        background-color: rgb(250, 248, 248);
        padding: 16px;
        .title{
            font-size: 18px;
            font-weight: 500;
        }
        .subtitle{
            font-size: 12px;
        }
        .addingbuttons{
            display: flex;
            gap: 10px;
            margin-top: 20px;
            .rightbutton{
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                border: 1px solid lightgray;
                padding: 2px 10px;
                border-radius: 5px ;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 8px;
                }
            }
        }
    }
    .addcategory{
        position: absolute;
        height: 100vh;
        width: 100%;
        // background-color: red;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        .formcontainer{
            width:350px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            background-color: white;
            min-height: 60vh;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 16px;
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    display: flex;
                    gap: 5px;
                    .word{
                        font-size: 17px;
                        font-weight: 500;
                    }
                    span{
                        display: flex;
                        align-items: center;
                    }
                }
                .right{
                    color: rgb(27, 27, 27);
                    font-weight: 300;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
            .formarea{
                display: flex;
                flex-direction: column;
                gap: 15px;
                .success{
                    color: green;
                }
                .error{
                    color: red;
                    font-size: 13px;
                }
                .loader{
                    font-size: 13px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                input{
                    outline: none;
                    padding: 5px 16px;
                }
                button{
                    padding: 5px;
                    border: none;
                    cursor: pointer;
                    color: white;
                    background: $amazongreen;
                    background-color: $amazongreen;
                }
                .category{
                    position: relative;
                    display: flex;
                    border: 1px solid black;
                    align-items: center;
                    padding-right: 5px;
                    .categorylist{
                        padding: 16px;
                        position: absolute;
                        top:20px;
                        left: 0;
                        min-height: 40vh;
                        display: flex;
                        flex-direction: column;
                        width: 400px;
                        background-color: white;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        z-index: 99999;
                        gap: 15px;
                        .title{
                            font-size: 13px;
                            font-weight: 500;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .closemodal{
                                cursor: pointer;
                            }
                        }
                        .catlistarea{
                            font-size: 13px;
                            display: flex;
                            flex-direction:column;
                            span{
                                padding-bottom: 5px;
                                border-bottom: 1px solid rgb(230, 227, 227);
                                cursor: pointer;
                                color: black;
                                &:hover{
                                    font-weight: 500;
                                    transform: scale(1.1);
                                    transform-origin: 0 100%; /* Set the transform origin to bottom left */
                                    transition: transform 0.3s ease;
                                    color: $amazongreen;
                                }
                            }
                           
                        }
                    }
                    input{
                        flex: 1;
                        border: none;
                        outline: none;
                    }
                    span{
                        color: gray;
                        cursor: pointer;
                    }
                    .comma{
                        font-size: 10px;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}