$amazongreen: #007185;
.previewproduct{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 16px;
    position: relative;
    min-height: 100vh;
    .loader{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        .containerl{
            width: 350px;
            // background-color: $amazongreen;
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    .heading{
        width: 60%;
    }
    .div{
        width: 60%;
        display: flex;
        gap: 10px;
    }
    .container{
        display: flex;
        flex: 2;
        flex-direction: column;
        gap: 10px;
        .first, .second,.third{
            background-color: white;
            padding: 16px;
            border-radius: 8px;
        }
        .first{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .title{
                font-size: 14px;
                font-weight: 500;
            }
            .images{
                display: flex;
                gap: 5px;
                img{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
              };        
        }
        .second{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title{
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .word{
                font-size: 13px;
            }
            .wordprice{
                .mwk{
                    font-size: 12px;
                }
                .figure{

                }
            }
        }
        .third{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title{
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .word{
                font-size: 13px;
            }
            .wordprice{
                display: flex;
                .mwk{
                    font-size: 10px;
                }
                .figure{
                    
                }
            }
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .first, .second,.third{
            background-color: white;
            padding: 16px;
            border-radius: 8px;
        }
        .third{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .namer{
                font-weight: 500;
            }
            .title{
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .word{
                font-size: 13px;
            }
            .wordprice{
                display: flex;
                .mwk{
                    font-size: 10px;
                }
                .figure{
                    
                }
            }
            .price{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
        .buttonn{
            margin-top: 10px;
            background-color: $amazongreen;
            color: white;
            border: none;
            padding: 7px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}
