$red: red;
.navbar{
    padding: 5px 16px;
    color: black;
    background-color:  white !important;
    display: flex;
    align-items: center;
    gap: 10px;
    .addproduct1{
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        .icon{
            background-color: $red;
            padding: 5px;
            font-size: 10px;
            color: white;
        }
    }
    .search{
        border: 1px solid rgb(201, 200, 200);
        padding: 5px;
        display: flex;
        align-items: center;
        flex: 1;
        input{
            flex: 1;
            border: none;
            outline: none;
            
        }
        .icon{
            font-size: 10px;
            display: flex;
            align-items: center;
        }
        .button{
            display: flex;
            align-items: center;
            background-color: $red;
            color: white;
            font-size: 13px;
            padding: 5px;
            cursor: pointer;
        }
    }
}