.message{
    display: flex;
.messageContainer{
    flex: 6;
    height: 100vh;
    .messageContainer{
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        gap: 5px;
        .left{
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 10px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            padding: 16px;
            .searchDiv{
                display: flex;
                align-items: center;
                border: 0.4px solid lightgray;
                padding: 5px;
                width: 400px;
                height: 25px;
                // border-radius: 8px;
                background-color: rgb(236, 233, 233);
                input{
                    flex: 1;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    &::placeholder{
                        font-size: 12px;
                    }
                }
                .searchIcon{
                    color: gray;
                    font-size: 16px;
                }
            }
            .messagesAlert{
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
            }
            ::-webkit-scrollbar {
                width: 5px;
              }
              
              ::-webkit-scrollbar-track {
                background: white;
              }
              
              ::-webkit-scrollbar-thumb {
                background: rgb(248, 224, 193);
              }
              
              .messagesAlert:hover::-webkit-scrollbar-thumb {
                background: rgb(212, 114, 15);
              }

        }
        .right{
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            .tapchat{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .title{
                    color: rgb(212, 114, 15);
                    font-size: 24px;
                }
                .description{
                    text-align: center;
                    font-weight: 300;
                    font-size: 12px;

                }
            }
            .normal{
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
                .imagemessagearea{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .imageArea{
                        width: 50%;
                        min-height: 50%;
                        background-color: white;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 10px;
                        gap: 10px;
                        .imagespace{
                            // flex: 1;
                            width: 100%;
                            height: 50%;
                            img{
                                width: 100%;
                                height: 400px;
                                object-fit: cover;
                            }
                            .close{
                                text-align: right;
                                cursor: pointer;
                            }
                        }
                        .textspace{
                            display: flex;
                            gap: 5px;
                            align-items: center;

                            input{
                                flex: 1;
                                background-color: rgb(244, 241, 241);
                                outline: none;
                                border: none;
                                height: 20px;
                                padding-top:10px;
                                padding-left: 10px;
                                padding-bottom: 10px;
                                border: 1px solid gray;
      
                            }
                        }
                    }
                }
                .top{
                    margin-top: 16px;
                    margin-left: 16px;
                    margin-right: 16px;
                    padding-bottom: 16px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    border-bottom: 1px solid rgb(241, 239, 239);
                    .selecteduserprofile{
                       width: 50px;
                       height: 50px;
                       img{
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        
                       }
                    }
                    .usernameandonline{
                        .username{
                            font-size: 13px;
                        }
                        .online{
                            font-size: 12px;
                            color:rgb(212, 114, 15);
                        }
    
                    }
    
                    
                }
                .messageBody{
                    padding-left: 16px;
                    padding-right: 16px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .messages{
                        height: 75vh;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        overflow-y: scroll;
                        .leftmessage{
                            display: flex;
                            justify-content: left;
                            .actualmessegaeleft{
                                background-color:rgb(244, 241, 241);
                                padding:5px 10px;
                                border-radius: 0px 10px 10px 10px;
                                max-width: 70%;
                                font-size: 13px;
                                .minutes{
                                    text-align:left;
                                    font-size: 10px;
                                    color: rgb(212, 114, 15);
                                }
                                .chatImage{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            .actualmessegaeleftImg{
                                .minutes{
                                    text-align:left;
                                    font-size: 10px;
                                    color: rgb(212, 114, 15);
                                    padding-left: 10px;

                                }
                            }
                        }
                        //rgb(244, 241, 241)
                        .rightmessage{
                            display: flex;
                            justify-content: right;
                            .actualmessageright{
                                // background-color:rgb(238, 184, 113);
                                background-color:rgb(75, 147, 219);
                                padding:5px 10px;
                                border-radius: 10px 0px 10px 10px;
                                max-width: 70%;
                                font-size: 13px;
                                margin-right: 10px;
                                color: white;
                                .minutes{
                                    text-align: right;
                                    font-size: 10px;
                                    color:black;

                                }
                                .chatImage{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            .actualmessagerightImg{
                                margin-right: 10px;
                                .minutes{
                                    padding-left: 10px;
                                    text-align: right;
                                    font-size: 10px;
                                    color:black;
                                } 
                            }
                        }
                    
                    }
                    ::-webkit-scrollbar {
                        width: 5px;
                      }
                      
                      ::-webkit-scrollbar-track {
                        background: white;
                      }
                      
                      ::-webkit-scrollbar-thumb {
                        background: rgb(248, 224, 193);
                      }
                      
                      .messages:hover::-webkit-scrollbar-thumb {
                        background: rgb(212, 114, 15);
                      }

                    .chatSpace{
                        height: 50px;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        .send{
                            cursor: pointer;
                        }
                        .getphotos{
                            cursor: pointer;
                        }
                        input{
                            flex: 1;
                            background-color: rgb(244, 241, 241);
                            outline: none;
                            border: none;
                            height: 20px;
                            padding-top:10px;
                            padding-left: 10px;
                            padding-bottom: 10px;
                            border-radius: 10px;

                        }
                    }
                }
            }
        }
    }
 }
}
.classIMGHolder{
    width: 200px;
    height: 250px;
    // background-color: lightgray; 
}
.chatImage{
    width: 200px;
    height: 250px;
    object-fit: cover;
    background-color: lightgray;
}
.imagetext{
    padding: 5px 10px;
    font-size: 12px;
    width: 180px;
    background-color: rgb(252, 247, 247);
}
.imagesender{
    cursor: pointer;
}



.messageMobile{
    display: flex;
    flex-direction: column;
    padding: 16px;
.messageContainer{
    flex: 6;
    height: 100vh;
    .messageContainer{
        // margin-left: 20px;
        // margin-right: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 5px;
        .left{
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 10px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            padding: 16px;
            .searchDiv{
                display: flex;
                align-items: center;
                border: 0.4px solid lightgray;
                padding-right: 10px;
                width: 400px;
                height: 25px;
                width: 100%;
                // border-radius: 8px;
                background-color: rgb(236, 233, 233);
                input{
                    flex: 1;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    &::placeholder{
                        font-size: 12px;
                    }
                }
                .searchIcon{
                    color: gray;
                    font-size: 16px;
                }
            }
            .messagesAlert{
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
            }
            ::-webkit-scrollbar {
                width: 5px;
              }
              
              ::-webkit-scrollbar-track {
                background: white;
              }
              
              ::-webkit-scrollbar-thumb {
                background: rgb(248, 224, 193);
              }
              
              .messagesAlert:hover::-webkit-scrollbar-thumb {
                background: rgb(212, 114, 15);
              }

        }
        .right{
            position: absolute;
            top: 0;
            left: 0;
            background-color: white;
            width: 100%;
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            .tapchat{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .title{
                    color: rgb(212, 114, 15);
                    font-size: 24px;
                }
                .description{
                    text-align: center;
                    font-weight: 300;
                    font-size: 12px;

                }
            }
            .normal{
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
                .imagemessagearea{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .imageArea{
                        width: 50%;
                        min-height: 50%;
                        background-color: white;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 10px;
                        gap: 10px;
                        .imagespace{
                            // flex: 1;
                            width: 100%;
                            height: 50%;
                            img{
                                width: 100%;
                                height: 400px;
                                object-fit: cover;
                            }
                            .close{
                                text-align: right;
                                cursor: pointer;
                            }
                        }
                        .textspace{
                            display: flex;
                            gap: 5px;
                            align-items: center;

                            input{
                                flex: 1;
                                background-color: rgb(244, 241, 241);
                                outline: none;
                                border: none;
                                height: 20px;
                                padding-top:10px;
                                padding-left: 10px;
                                padding-bottom: 10px;
                                border: 1px solid gray;
      
                            }
                        }
                    }
                }
                .top{
                    margin-top: 16px;
                    margin-left: 16px;
                    margin-right: 16px;
                    padding-bottom: 16px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    border-bottom: 1px solid rgb(241, 239, 239);
                    .selecteduserprofile{
                       width: 50px;
                       height: 50px;
                       img{
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        
                       }
                    }
                    .usernameandonline{
                        flex: 1;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        .closecurrentchat{
                            position: absolute;
                            right: 0;
                            top: 5px;
                            font-size: 18px;
                            cursor: pointer;
                        }
                        .username{
                            font-size: 13px;
                        }
                        .online{
                            font-size: 12px;
                            color:rgb(212, 114, 15);
                        }
    
                    }
    
                    
                }
                .messageBody{
                    padding-left: 16px;
                    padding-right: 16px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .messages{
                        height: 75vh;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        overflow-y: scroll;
                        .leftmessage{
                            display: flex;
                            justify-content: left;
                            .actualmessegaeleft{
                                background-color:rgb(244, 241, 241);
                                padding:5px 10px;
                                border-radius: 0px 10px 10px 10px;
                                max-width: 70%;
                                font-size: 13px;
                                .minutes{
                                    text-align:left;
                                    font-size: 10px;
                                    color: rgb(212, 114, 15);
                                }
                                .chatImage{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            .actualmessegaeleftImg{
                                .minutes{
                                    text-align:left;
                                    font-size: 10px;
                                    color: rgb(212, 114, 15);
                                    padding-left: 10px;

                                }
                            }
                        }
                        //rgb(244, 241, 241)
                        .rightmessage{
                            display: flex;
                            justify-content: right;
                            .actualmessageright{
                                // background-color:rgb(238, 184, 113);
                                background-color:rgb(75, 147, 219);
                                padding:5px 10px;
                                border-radius: 10px 0px 10px 10px;
                                max-width: 70%;
                                font-size: 13px;
                                margin-right: 10px;
                                color: white;
                                .minutes{
                                    text-align: right;
                                    font-size: 10px;
                                    color:black;

                                }
                                .chatImage{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            .actualmessagerightImg{
                                margin-right: 10px;
                                .minutes{
                                    padding-left: 10px;
                                    text-align: right;
                                    font-size: 10px;
                                    color:black;
                                } 
                            }
                        }
                    
                    }
                    ::-webkit-scrollbar {
                        width: 5px;
                      }
                      
                      ::-webkit-scrollbar-track {
                        background: white;
                      }
                      
                      ::-webkit-scrollbar-thumb {
                        background: rgb(248, 224, 193);
                      }
                      
                      .messages:hover::-webkit-scrollbar-thumb {
                        background: rgb(212, 114, 15);
                      }

                    .chatSpace{
                        height: 50px;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        .send{
                            cursor: pointer;
                        }
                        .getphotos{
                            cursor: pointer;
                        }
                        input{
                            flex: 1;
                            background-color: rgb(244, 241, 241);
                            outline: none;
                            border: none;
                            height: 20px;
                            padding-top:10px;
                            padding-left: 10px;
                            padding-bottom: 10px;
                            border-radius: 10px;

                        }
                    }
                }
            }
        }
    }
 }
}
