$amazongreen: #007185;
.addpage{
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    position: relative;
    .loaderb{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6); /* Black with 80% opacity */
        z-index: 999;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loading{
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        .containerr{
            width: 340px;
            height: 300px;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 13px;
            .icon{
                font-size: 24px;
                color: $amazongreen;
            }
        }
    }
    .header{
        font-size: 17px;
        width: 60%;
        font-weight: 500;
        display: flex;
        gap: 10px;
        align-items: center;
        .icon{
            font-size: 13px;
            font-weight: 300;
        }
    }
    .container{
        width: 60%;
        min-height: 100vh;
        display: flex;
        gap: 10px;
        .left{
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .productnamedescription{
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                background-color: white;
                .title{
                    font-size: 13px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .fade{
                        font-size: 10px;
                        width: 10px;
                    }
                }
                input{
                    border-radius: 5px;
                }
                .name{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    position: relative;
                    .alert{
                        position: absolute;
                        background-color: white;
                        padding: 5px;
                        color: red;
                        right: 20px;
                        top: 3px;
                        z-index: 9999;
                        font-size: 13px;
                    }
                    input{
                        outline: none;
                        padding: 7px 10px;
                        border: 1px solid gray;
                    }
                    .discount{
                        display: flex;
                        gap: 10px;
                        .item{
                            display: flex;
                            gap: 2px;
                            align-items: center;
                            .icon{
                                color: black;
                                font-size: 15px;
                                cursor: pointer;
                            }
                            .nottick {
                                align-self: flex-start;
                                margin-right: 5px;
                            }
                            .word{
                                font-size: 13px;
                                display: flex;
                                align-items: center;

                            }
                            .continue{
                                font-size: 12px;
                            }
                        }
                    }
                    .div{
                        font-size: 13px;
                        margin-top: 10px;
                    }
                }
                .description{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    position: relative;
                    .alert{
                        position: absolute;
                        background-color: white;
                        padding: 5px;
                        color: red;
                        right: 20px;
                        top: 3px;
                        z-index: 9999;
                        font-size: 13px;
                    }
                    input{
                        outline: none;
                        padding: 7px 10px;
                        border: 1px solid gray;
                    }
                }
                .pricediv{
                    display: flex;
                    gap: 1PX;
                    align-items: center;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 0px 5px;
                    position: relative;
                    span{
                        font-size: 10px;
                        color: gray;
                        display: flex;
                        align-items: center;}
                    input{
                        border: none;
                        outline: none;
                    }
                    .selectionList{
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                        gap: 10px;
                        position: absolute;
                        z-index: 99999;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    }
                    .alert{
                        position: absolute;
                        background-color: white;
                        padding: 5px;
                        color: red;
                        right: 20px;
                        top: -10px;
                    }
                }
            }
        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .productnamedescription{
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                background-color: white;
                .title{
                    font-size: 13px;
                }
                .wordtitle{
                    font-size: 13px;
                    margin-top: 5px;
                }
                input{
                    border-radius: 5px;
                    flex: 1;
                }
                .name{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    position: relative;
                    .alert1{
                        position: absolute;
                        background-color: white;
                        padding: 5px;
                        color: red;
                        right: 20px;
                        top: 27px;
                        z-index: 9999;
                        font-size: 13px;
                    }
                    .title{
                        font-weight: 500;
                    }
                    input{
                        outline: none;
                        padding: 7px 10px;
                        border: 1px solid gray;
                    }
                    .discount{
                        display: flex;
                        gap: 10px;
                        .item{
                            display: flex;
                            gap: 2px;
                            align-items: center;
                            .icon{
                                color: black;
                                font-size: 15px;
                                cursor: pointer;
                            }
                            .nottick {
                                align-self: flex-start;
                                margin-right: 5px;
                            }
                            .word{
                                font-size: 13px;
                                display: flex;
                                align-items: center;

                            }
                            .continue{
                                font-size: 12px;
                            }
                        }
                    }
                    .div{
                        font-size: 13px;
                        margin-top: 10px;
                    }
                }
                .description{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    input{
                        outline: none;
                        padding: 7px 10px;
                        border: 1px solid gray;
                    }
                }
                .pricediv{
                    display: flex;
                    gap: 1PX;
                    align-items: center;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 0px 5px;
                    position: relative;
                    span{
                        font-size: 10px;
                        color: gray;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    input{
                        border: none;
                        outline: none;
                    }
                    .selectionList{
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                        gap: 10px;
                        position: absolute;
                        z-index: 99999;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                        background-color: white;
                        width: 200px;
                        top: 30px;
                        max-height: 70vh;
                        overflow: hidden;
                        .headingList{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .icon{
                                font-size: 12px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                            }
                            .title{
                                display: flex;
                                align-items: center;
                            }
                        }
                        .containerList{
                            font-size: 13px;
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            font-size: 14px;
                            span{
                                cursor: pointer;
                                padding-bottom: 5px;
                                border-bottom:  solid 1px rgb(228, 224, 224);
                                font-size: 13px;
                                transition: transform 0.3s, color 0.3s;
                                &:hover{
                                    transform: scale(1, 0.9); /* Zoom out effect */
                                    color: $amazongreen; /* Change the text color to black on hover */
                                }
                            }
                        }
                    }
                }
                .button{
                    background-color: black;
                    padding: 8px;
                    display: flex;
                    gap: 2px;
                    color: white;
                    border-radius: 5px;
                    align-items: center;
                    justify-self: center;
                    font-size: 12px;
                    cursor: pointer;
                }
                .buttonn{
                    font-size: 13px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
        }
    }
}
.imageproducts{
    position: relative;
    .onhoverfada{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6); /* Black with 80% opacity */
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        .mycan{
            cursor: pointer;
        }

    }
}

.lol{
    margin-bottom: 15px;
}